<template>
  <div>
    <ReviewSystemCategoryFilter
      v-if="$can('filter', 'module_system_settings.settings.admin_subject.review_system_category')"
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage"
    />
    <b-card>
      <TitleTable titleTable="Review System Category" iconTable="MapIcon" />
      <ReviewSystemCategoryAdd
        :is-add-new-review-system-category-sidebar-active.sync="
          isAddNewReviewSystemCategorySidebarActive
        "
        @createReviewSystemCategory="createReviewSystemCategory"
      />
      <ReviewSystemCategoryEdit
        v-if="isEditReviewSystemSidebarActive"
        :is-edit-review-system-sidebar-active.sync="
          isEditReviewSystemSidebarActive
        "
        :itemEdit="itemEdit"
        @editReviewSystemSuccess="editReviewSystemSuccess"
      />
      <div class="m-1">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Entries") }}</label>
          </b-col>

          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <b-button
                variant="primary"
                @click="isAddNewReviewSystemCategorySidebarActive = true"
                :disabled="loading"
                v-if="$can('store', 'module_system_settings.settings.admin_subject.review_system_category')"
              >
                <span v-if="!loading" class="text-nowrap"
                  >{{ $t("Add") }} Review System Category</span
                >
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        outlined
        bordered
        class="position-relative"
        responsive
        :items="items"
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
        :sort-by.sync="sortBy"
        :sort-asc.sync="isSortDirDesc"
      >
        <template #cell(name)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.name
            }}</span>
          </div>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.status ? $t("Active") : $t("Inactive")
            }}</span>
          </div>
        </template>
        <template #cell(review_of_system)="data">
          <div class="text-nowrap">
            <ul class="list-unstyled my-2 facility">
              <li v-for="(item, i) in data.item.review_of_system" :key="i">
                <b-badge variant="light-primary"
                  ><span>{{ item.name }}</span></b-badge
                >
              </li>
            </ul>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-b-tooltip.hover.top="'Edit'"
            v-if="$can('update', 'module_system_settings.settings.admin_subject.review_system_category')"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            @click="
              editReview(data.item);
            "
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <b-button
            v-b-tooltip.hover.top="'Change Status'"
            v-if="$can('update', 'module_system_settings.settings.admin_subject.review_system_category')"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-warning"
            class="btn-icon"
            @click="changeReview(data.item)"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged"
      />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BTable,
  BCard,
  BFormInput,
  BCol,
  BRow,
  BButton,
  BBadge,
  BPagination,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import ReviewSystemCategoryAdd from "./ReviewSystemCategoryAdd.vue";
import ReviewSystemCategoryEdit from "./ReviewSystemCategoryEdit.vue";
import ReviewSystemCategoryFilter from "./ReviewSystemCategoryFilter.vue";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";

import axiosRSC from "@/services/admin/consults/reviewSystemCategories";
import { ref } from "@vue/composition-api";

export default {
  components: {
    BTable,
    BCard,
    BFormInput,
    BCol,
    BRow,
    BButton,
    BBadge,
    BPagination,
    vSelect,
    ToastNotification,
    ReviewSystemCategoryAdd,
    ReviewSystemCategoryEdit,
    ReviewSystemCategoryFilter,
    TitleTable,
    SpinnerLoading,
    GoBack,
    PaginationTable,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  mounted() {
    this.getReviewSystemCategory();
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getReviewSystemCategory();
      } else {
        this.searchFilter(this.reviewFilter);
      }
    },
  },
  data() {
    return {
      tableColumns: [
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        { key: "review_of_system", sortable: true },
        { key: "status", sortable: true },
        { key: "actions" },
      ],
      items: [],
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      sortBy: "id",
      isSortDirDesc: true,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      currentPage: {
        page: 1,
      },
      totalUsers: 0,
      isAddNewReviewSystemCategorySidebarActive: false,
      isEditReviewSystemSidebarActive: false,
      loading: false,
      citiesFilter: null,
      itemEdit: {},
      reviewFilter: null,
      filtro: false,
    };
  },
  methods: {
    getReviewSystemCategory() {
      axiosRSC
        .reviewSystemCategoryList(this.perPage)
        .then(({ data, total, current_page, ...res }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosRSC
          .reviewSystemCategoryPagination(this.perPage, page)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      } else {
        axiosRSC
          .reviewSystemCategoryFilterPagination(
            this.perPage,
            page,
            this.reviewFilter
          )
          .then(({ registro: { data, total, current_page, ...res } }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
    createReviewSystemCategory({ status }) {
      if (status) {
        this.$refs.toast.success("Review System added successfully");
        this.isAddNewReviewSystemCategorySidebarActive = false;
        this.pageChanged(this.currentPage.page);
      } else {
        this.$refs.toast.danger("Review System not added");
        this.isAddNewReviewSystemCategorySidebarActive = false;
      }
    },
    editReview(item) {
      const dataEdit = {
        id: item.id,
        category: item.review_of_system.map(res => res.id),
        name: item.name,
        status: item.status
      }
      this.itemEdit = ref(JSON.parse(JSON.stringify(dataEdit)));
      this.isEditReviewSystemSidebarActive = true
    },
    editReviewSystemSuccess(value) {
      this.isEditReviewSystemSidebarActive = false;
      this.itemEdit = {};
      if (value[1] == "catch") {
        this.$refs.toast.danger("Review System not updated");
      } else {
        if (value) {
          this.$refs.toast.success("Review System updated successfully");
          this.pageChanged(this.currentPage.page);
        }
      }
    },
    changeReview(item) {
      axiosRSC
        .reviewSystemCategoryUpdate(item.id, { ...item, status: !item.status })
        .then(() => {
          this.pageChanged(this.currentPage.page);
          this.$refs.toast.success("Review System changed successfully");
        })
        .catch(() => {
          this.$refs.toast.danger("Review System not changed");
        });
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true;
        this.reviewFilter = value;
        axiosRSC
          .reviewSystemCategoryFilter(this.perPage, value)
          .then(({ registro: { data, total, current_page, ...res } }) => {
            this.loading = false;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.getReviewSystemCategory();
      }
    },
    filterPage(value) {
      this.filtro = value;
    },
  },
};
</script>